<template>
  <div class="index_container container">
    <b-alert :show="hasPayOrder">
      <div class="d-flex justify-content-between align-items-center">
        <div class="mr-3">
          <div>You have unpaid orders</div>
        </div>
        <b-link @click="toMyOrderList">To pay</b-link>
      </div>
    </b-alert>
    <b-alert :show="hasPayRechargeOrder" variant="secondary">
      <div class="d-flex justify-content-between align-items-center">
        <div class="mr-3">
          <div>You have recharge order to pay</div>
        </div>
        <b-link @click="toMyRegOrderList">To pay</b-link>
      </div>
    </b-alert>
    <div
      class="tabs_content d-block d-lg-none d-xl-none"
      v-if="!showCreateCardH5"
    >
      <div class="d-flex">
        <div
          class="top_tabs h5"
          :class="{ top_tabs_active: tabIndex == 1 }"
          @click="tabChange(1)"
        >
          Cards
        </div>
        <div
          class="top_tabs h5"
          :class="{ top_tabs_active: tabIndex == 2 }"
          @click="tabChange(2)"
        >
          Activity
        </div>
        <div class="right_btn">
          <b-button pill variant="primary" @click="openCardh5"
            >New Card</b-button
          >
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block" style="margin-bottom: 3rem">
      <div class="row">
        <div class="col-12">
          <div class="open_card_btn">
            <b-button
              squared
              block
              active-class="open_card_activer"
              variant="outline-primary"
              @click="openCard"
            >
              <div class="icon_btn">
                <b-icon class="bg-primary" icon="plus"></b-icon>
              </div>
              <div class="right_btn text-left" style="padding-left: 4rem">
                Create a New Card
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showCreateCardH5">
      <b-row>
        <b-col
          :md="12"
          :lg="5"
          :xl="4"
          :cols="12"
          class="d-lg-block"
          :class="{ 'd-none': tabIndex != 1 }"
        >
          <div class="d-flex">
            <h3 class="title mr-3 d-none d-lg-block">Cards</h3>
          </div>
          <div
            style="transform: scale(0.8); transform-origin: left"
            class="d-flex mb-3"
          >
            <b-button size="sm" @click="hideBalanceClick('all')"
              >Hide All Balance</b-button
            >
            <b-button
              size="sm"
              variant="info"
              class="ml-2"
              @click="hideAllDisCard()"
              >Hide all disabled cards</b-button
            >
          </div>
          <div
            class="d-flex justify-content-center"
            v-if="cardList.length > 0"
            v-for="(card, index) in cardList"
            :key="index"
          >
            <div
              class="cards-item"
              v-if="!(hideAlldisabledCard && card.status == 1)"
            >
              <div
                class="card_item"
                :class="{ disabled_card: card.status == 1 }"
              >
                <div>
                  <button class="copy_iconf" @click="handleCopy(card)">
                    Copy CardInfo
                  </button>
                </div>
                <div class="text-right">
                  <h2 class="mt-1" v-if="isHideBalance(card.id)">
                    DaVinciCard
                  </h2>
                  <h2 class="mt-1" v-else>$ {{ card.balance }}</h2>
                </div>
                <div class="text text-right">CARD NUMBER</div>
                <div class="row title">
                  <div
                    class="col-3"
                    v-for="(arr, index) in card.binArr"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div v-for="(item, i) in arr" style="width: 15px">
                        <span class="mr-1">{{ item }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <b-row>
                  <b-col :cols="9" class="text-right">
                    <div class="text">Valid Thru</div>
                    <div class="title">{{ card.expireDate }}</div>
                  </b-col>
                  <b-col :cols="3" class="text-right">
                    <div class="text">CVV</div>
                    <div class="title" v-if="showCVVId == card.id">
                      {{ card.cvv }}
                    </div>
                    <div class="title" v-else>***</div>
                  </b-col>
                </b-row>
                <div class="text-left organization">
                  <h3>{{ card.organize }}</h3>
                </div>
                <div class="disabled_card_box">
                  <div class="dis_icon">
                    <i class="icon icon-discard"></i>
                    <p style="color: red; background-color: #ffffffa3">
                      卡餘額大於$1時，將解除禁用
                    </p>
                  </div>
                </div>
              </div>

              <div class="text-right mt-2" style="max-width: 350px">
                <!-- <b-button
                  class="text-right mt-2"
                  style="transform: scale(0.8); padding-left: 0"
                  variant="info"
                  size="sm"
                  @click="handleCopy(card.cardNo)"
                  >Copy CardNO</b-button
                > -->
                <!-- <b-button size="sm" style="transform: scale(0.8)"
                  >Copy CardNO</b-button
                > -->
                <b-button
                  size="sm"
                  style="transform: scale(0.8)"
                  @click="hideBalanceClick(card.id)"
                  >Hide Balance</b-button
                >
                <b-button
                  class="mr-3 ml-3"
                  style="transform: scale(0.8)"
                  variant="info"
                  size="sm"
                  @click="showCVVClick(card.id)"
                  >View CVV</b-button
                >
                <b-button
                  variant="primary"
                  style="transform: scale(0.8)"
                  :disabled="!card.topUp"
                  size="sm"
                  @click="rechargeCard(card)"
                  >Recharge</b-button
                >
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="open_card_content d-none d-lg-block d-xl-block">
              <div
                class="open_card"
                style="margin-bottom: 2rem"
                @click="openCard"
              >
                <b-icon scale="6" class="bg-primary" icon="plus"></b-icon>
              </div>
            </div>
            <div class="open_card_content d-block d-lg-none d-xl-none">
              <div
                class="open_card"
                style="margin-bottom: 2rem"
                @click="openCardh5"
              >
                <b-icon scale="6" class="bg-primary" icon="plus"></b-icon>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          :lg="7"
          :xl="7"
          offset-xl="1"
          :cols="12"
          class="d-lg-block"
          :class="{ 'd-none': tabIndex == 1 }"
        >
          <h3 class="title d-none d-lg-block">All Activity</h3>
          <div class="mb-2">
            <b-button variant="primary" size="sm" @click="updateCardTrans"
              >Update bill</b-button
            ><b-spinner
              v-if="updateTransLoad"
              class="ml-2"
              small
              variant="secondary"
            ></b-spinner>
          </div>
          <div style="margin-top: 1.5rem" v-if="transList.length <= 0">
            <i style="color: grey" class="d-none d-lg-block"
              >No transactions yet</i
            >
            <div class="text-center">
              <i style="color: grey" class="d-block d-lg-none"
                >No transactions yet</i
              >
            </div>
          </div>
          <div v-else class="trans_list_content">
            <b-row>
              <b-col :xl="10">
                <div
                  v-for="(item, index) in transList"
                  :key="index"
                  class="trans_items"
                >
                  <div class="d-flex justify-content-between">
                    <div>{{ item.cardNo }}</div>
                    <div
                      class="mr-1"
                      :class="{
                        decrease: item.type == 'DECREASE',
                        increase: item.type == 'INCREASE',
                      }"
                      style="white-space: nowrap"
                    >
                      <span v-if="item.type == 'DECREASE'">-</span>
                      <span v-if="item.type == 'INCREASE'">+</span>
                      $ {{ item.amount }}
                    </div>
                    <div>{{ item.transactionTime }}</div>
                  </div>
                  <div v-if="item.result">
                    Transaction Result: {{ item.result }}
                  </div>
                  <div v-if="item.merchant">
                    Bill details: {{ item.merchant }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-modal
      id="modal-open-card"
      hide-header
      hide-footer
      :centered="isRecharge"
      :size="isRecharge ? 'md' : 'xl'"
    >
      <modal-open-card
        :isRecharge="isRecharge"
        :currentRechargeCard="currentRechargeCard"
        @closeModalCard="closeModalCard"
        @confirmPayment="confirmPayment"
      ></modal-open-card>
    </b-modal>
    <b-modal id="payment-modal" hide-header hide-footer :centered="isRecharge">
      <payment-container
        :isRecharge="isRecharge"
        :showClose="true"
        @closepayModal="closepayModal"
        :payForm="payForm"
        @hidePaid="hidePaid"
      >
      </payment-container>
    </b-modal>

    <div class="create_card_h5" v-if="showCreateCardH5">
      <modal-open-card
        :issmh5="true"
        @closeModalCardH5="closeModalCardH5"
        @confirmPayment="confirmPaymentH5"
        v-if="!showPaymentContainer"
      ></modal-open-card>
      <div v-if="showPaymentContainer" class="payment_h5">
        <div class="p-3 d-flex justify-content-between align-items-center">
          <div>
            <h6 @click="closePaymentH5">
              <i class="icon icon-arrowleft"></i>Back
            </h6>
          </div>
          <div>
            <h4>Create a New Card</h4>
          </div>
        </div>
        <payment-container
          :payForm="payForm"
          @hidePaid="hidePaid"
        ></payment-container>
      </div>
    </div>
  </div>
</template>

<script>
import modalOpenCard from '@/components/modal-open-card.vue';
import paymentContainer from '@/components/payment-container.vue';

import { getInfo, editInfo } from '@/api/user';
import {
  getOpenCardRecords,
  getIndexCards,
  getIndexTransactions,
  updateCardTransactions,
  getRechargeRecords,
} from '@/api/card';

export default {
  components: {
    modalOpenCard,
    paymentContainer,
  },
  data() {
    return {
      //
      keyList: [],
      bgObj: {
        bg: require('@/assets/index_v1/index_v1_bg.png'),
        size: '50%',
      },
      hideAllBalance: [],
      showCVVId: '', //显示卡id cvv
      tabIndex: 1,
      showCreateCardH5: false,
      showPaymentContainer: false,
      payForm: null,
      usdtAddress: '',
      showUsdtAddr: false,
      cardList: [],
      //开卡列表参数
      openRecordsForm: {
        pageNum: 1,
        pageSize: 20,
        status: null,
      },
      hasPayOrder: false,
      hasPayRechargeOrder: false,
      cardsForm: {
        pageNum: 1,
        pageSize: 20,
      },
      cardLoading: false,
      totalCards: 0,
      TransForm: {
        cardId: '', //卡片ID（为空查询所有卡片账单）
        pageNum: 1,
        pageSize: 15,
      },
      transList: [],
      totalTrans: 0,
      transLoading: false,
      updateTransLoad: false,
      isRecharge: false,
      currentRechargeCard: {},
      hideAlldisabledCard: false,
    };
  },
  created() {
    if (!this.$store.state.user.token) {
      this.$router.replace({
        path: '/login',
      });
    } else {
      this.getCardList();
      this.getOpenCardList();
      this.getIndexTransactionsList();
      this.updateCardTrans();
      //自动刷新获取数据(1000*60*60=>毫秒*秒*分钟，此时表示为5分钟)
      setInterval(() => {
        this.updateCardTrans();
      }, 1000 * 60 * 10);
    }
    this.$emit('imageBg', this.bgObj);
  },
  filters: {
    thruFormatt(time) {
      let t = time.split('-');
      return t[1] + '/' + t[2];
    },
    // 0正常，1停用
    getOrderStatus(status) {
      if (status == 0) {
        return 'normal';
      } else {
        return 'Deactivate';
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    mycopy(item, e) {
      console.log(item, '------mycopy----------', e);
    },
    handleCopy(e) {
      this.keyList = [];
      const newE = {
        cardNo: e.cardNo,
        cvv: e.cvv,
        expireDate: e.expireDate,
        organize: e.organize,
      };
      Object.keys(newE).forEach((v) => {
        let str = v + ':' + newE[v];
        this.keyList.push(str);
      });
      let conpyStr = this.keyList.join(' | ');
      var input1 = document.createElement('input'); // 直接构建input
      input1.value = conpyStr; // 设置内容
      document.body.appendChild(input1);
      input1.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input1); // 删除临时实例
      this.$bvToast.toast('Copy succeeded', {
        title: 'Tips',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        solid: true,
      });
      // console.log('复制成功');
    },
    isHideBalance(id) {
      return this.hideAllBalance.indexOf(id) >= 0;
    },
    hideBalanceClick(id) {
      if (id == 'all') {
        if (this.hideAllBalance.length == this.cardList.length) {
          this.hideAllBalance = [];
          return;
        } else {
          this.cardList.map((item) => {
            if (this.hideAllBalance.indexOf(item.id) < 0) {
              this.hideAllBalance.push(item.id);
            }
          });
        }
        return;
      }
      let index = this.hideAllBalance.indexOf(id);
      if (index >= 0) {
        this.hideAllBalance.splice(index, 1);
      } else {
        this.hideAllBalance.push(id);
      }
    },
    //隐藏所有禁用的卡
    hideAllDisCard() {
      this.hideAlldisabledCard = !this.hideAlldisabledCard;
    },
    showCVVClick(id) {
      if (this.showCVVId == id) {
        this.showCVVId = '';
      } else {
        this.showCVVId = id;
      }
    },
    getCardList() {
      getIndexCards(this.cardsForm).then((res) => {
        if (this.cardLoading) {
          this.cardList = this.cardList.concat(res.rows);
        } else {
          this.cardList = [];
          this.cardList = res.rows;
        }
        this.totalCards = res.total;
        this.cardLoading = false;

        this.cardList.map((item) => {
          this.$set(item, 'binArr', [[], [], [], []]);
          let arr = item.cardNo.toString().split('');
          for (let i = 0, j = 0; i < 16; i++) {
            if (arr[i]) {
              item.binArr[j].push(arr[i]);
            } else {
              item.binArr[j].push('x');
            }
            if (i % 4 == 3) {
              j++;
            }
          }
        });
      });
    },
    handleScroll() {
      let st = document.documentElement.scrollTop || document.body.scrollTop;
      let ch = document.documentElement.clientHeight;
      let sh = document.documentElement.scrollHeight;
      let w = window.innerWidth || document.documentElement.clientWidth;
      if (Math.floor(st + ch + 2) >= sh) {
        if (w < 992) {
          if (this.tabIndex == 1) {
            this.isMdGetList(true, false);
          } else {
            this.isMdGetList(false, true);
          }
        } else {
          this.isMdGetList(true, true);
        }
      }
    },
    isMdGetList(tab1, tab2) {
      if (
        tab1 &&
        !this.cardLoading &&
        this.cardsForm.length < this.totalCards
      ) {
        //卡片列表
        this.cardsForm.pageNum++;
        this.cardLoading = true;
        this.getCardList();
      }
      if (
        tab2 &&
        !this.transLoading &&
        this.transList.length < this.totalTrans
      ) {
        //交易记录
        this.TransForm.pageNum++;
        this.transLoading = true;
        this.getIndexTransactionsList();
      }
    },
    getIndexTransactionsList() {
      getIndexTransactions(this.TransForm).then((res) => {
        if (this.transLoading) {
          this.transList = this.transList.concat(res.rows);
        } else {
          this.transList = [];
          this.transList = res.rows;
        }
        this.transLoading = false;
        this.totalTrans = res.total;
      });
    },
    //更新账单记录
    updateCardTrans() {
      this.updateTransLoad = true;
      updateCardTransactions().finally(()=>{
        this.updateTransLoad = false;
        this.TransForm.pageNum = 1;
        this.transList = [];
        this.getIndexTransactionsList();
        // 刷新银行卡
        this.getCardList();
      });
    },
    //卡充值
    rechargeCard(card) {
      this.currentRechargeCard = card;
      this.isRecharge = true;
      this.$bvModal.show('modal-open-card');
    },
    openCard() {
      this.$bvModal.show('modal-open-card');
    },
    closeModalCard() {
      this.$bvModal.hide('modal-open-card');
      setTimeout(() => {
        this.isRecharge = false;
      }, 500);
    },
    tabChange(index) {
      this.tabIndex = index;
    },
    openCardh5() {
      this.showCreateCardH5 = true;
    },
    closeModalCardH5() {
      this.showCreateCardH5 = false;
    },
    //再次确认支付
    confirmPayment(payForm) {
      this.payForm = payForm;
      this.$bvModal.hide('modal-open-card');
      this.$bvModal.show('payment-modal');
    },
    //再次确认支付 h5
    confirmPaymentH5(payForm) {
      this.payForm = payForm;
      this.showPaymentContainer = true;
    },
    //保存usdt地址
    saveUsdtAddress() {
      if (this.usdtAddress) {
        let info = {
          usdtAddress: this.usdtAddress,
        };
        editInfo(info).then((res) => {
          this.showUsdtAddr = false;
          this.$bvModal.hide('modal-input-usdtaddress');
          if (this.showCreateCardH5) {
            this.showPaymentContainer = true;
          } else {
            this.$bvModal.show('payment-modal');
          }
          this.$store.dispatch('GetInfo');
        });
      }
    },
    closePaymentH5() {
      this.showPaymentContainer = false;
    },
    hidePaid() {
      this.$bvModal.hide('payment-modal');
      this.showPaymentContainer = false;
      this.showCreateCardH5 = false;
      setTimeout(() => {
        this.isRecharge = false;
      }, 500);
      this.hideResetList();
    },
    closepayModal() {
      this.$bvModal.hide('payment-modal');
      setTimeout(() => {
        this.isRecharge = false;
      }, 500);
      this.hideResetList();
    },
    hideResetList() {
      this.openRecordsForm.pageNum = 1;
      this.cardsForm.pageNum = 1;
      this.TransForm.pageNum = 1;
      this.cardList = [];
      this.transList = [];
      this.getCardList();
      this.getOpenCardList();
      this.getIndexTransactionsList();
    },
    //判断有未支付的开卡记录
    getOpenCardList() {
      getOpenCardRecords(this.openRecordsForm).then((res) => {
        this.hasPayOrder = false;
        res.rows.map((item) => {
          if (item.status == 0) {
            this.hasPayOrder = true;
          }
        });
      });
      getRechargeRecords(this.openRecordsForm).then((res) => {
        this.hasPayRechargeOrder = false;
        res.rows.map((item) => {
          if (item.status == 0) {
            this.hasPayRechargeOrder = true;
          }
        });
      });
    },
    toMyOrderList() {
      this.$router.push({
        path: '/setting?tag=' + 'order',
      });
    },
    toMyRegOrderList() {
      this.$router.push({
        path: '/setting?tag=' + 'recharge',
      });
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
.index_container {
  position: relative;

  .tabs_content {
    border-bottom: 2px solid #ccc;
    margin-bottom: 1.5rem;

    .top_tabs {
      line-height: 2.4em;
      text-align: center;
      border-radius: 4px 4px 0 0;
      color: #656565;
      flex: 1;
      cursor: pointer;

      &.h5 {
        margin-bottom: 0;
        font-weight: bolder;
      }

      &:hover {
        background-color: #f6f6f7;
        border-bottom: 3px solid #f6f6f7;
      }

      &.top_tabs_active {
        color: $theme;
        border-bottom: 3px solid $theme;
      }
    }

    .right_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px;

      .btn {
        font-weight: bolder;

        &.btn-primary {
          background-color: $theme;
          border-color: $theme;
        }

        &:hover {
          background-color: $btn-color;
          border-color: $btn-color;
        }
      }
    }
  }

  .open_card_btn {
    display: flex;
    border: 2px solid $theme;
    border-radius: 2px;

    .btn {
      flex-flow: 1;
      color: #333;
      display: flex;
      padding: 0;
      border: 0;
      line-height: 2.4em;
      background-color: #fff;

      .icon_btn {
        color: #fff;
        align-self: stretch;
        background-color: $theme;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 2.4em;

        .bg-primary {
          background-color: $theme !important;
        }

        .b-icon.bi {
          margin: 0;
        }
      }

      .right_btn {
        text-align: center;
        flex: 1;
      }

      &:hover {
        color: #fff;
        background-color: $btn-color;
      }
    }

    .open_card_activer {
      color: #fff;
    }
  }

  .card_content {
    margin-top: 40px;

    .title {
      margin-bottom: 30px;
    }
  }

  .open_card_content {
    width: 100%;
    max-width: 350px;
  }

  .open_card {
    color: #fff;
    background-color: $theme;
    height: 215px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 5px 7px 4px #a9a9a9;
    cursor: pointer;
    margin-top: 1.5rem;

    .bg-primary {
      background-color: $theme !important;
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  .cards-item {
    margin-bottom: 2rem;
  }
  .card_item:hover .copy_iconf {
    display: block;
  }
  .card_item {
    color: #fff;
    background-color: #000;
    height: 215px;
    max-width: 350px;
    border-radius: 10px;
    box-shadow: 0 5px 7px 4px #a9a9a9;
    cursor: pointer;
    padding: 1rem 1.5rem;
    background-image: url(../assets/index/card_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    .copy_iconf {
      position: absolute;
      transform: scale(0.8);
      right: 5px;
      bottom: 12px;
      background: rgb(241, 228, 228);
      display: none;
      // color: white;
      // transform: translate(-50%, -50%);
    }

    &:hover {
      transform: scale(1.03);
      display: block;
    }

    .text {
      font-size: 0.7rem;
    }

    .title {
      font-size: 1.4rem;
    }

    .organization {
      text-transform: uppercase;
      font-weight: bolder;

      h3 {
        margin-bottom: 0;
      }
    }

    .disabled_card_box {
      display: none;
    }

    &.disabled_card {
      position: relative;
      .disabled_card_box {
        background-color: #ccc8;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        border-radius: 10px;
        display: block;

        .dis_icon {
          text-align: center;
          margin-top: 20px;
        }

        .icon-discard {
          color: #cbcbcb;
          font-size: 7rem;
        }
      }
      &:hover {
        transform: scale(1);
      }
    }
  }

  .create_card_h5 {
    margin-top: -1rem;

    .payment_h5 {
      background-color: #fff;
    }
  }

  .line {
    background-color: #ccc;
    height: 1px;
    width: 30%;
  }

  .trans_list_content {
    border-radius: 5px;
    // margin-top: -3rem;

    .trans_items {
      border: 1px solid #ccc;
      background-color: #fff;
      border-radius: 5px;
      margin-bottom: 0.8rem;
      padding: 0.6rem 1rem;

      .decrease {
        color: red;
      }
      .increase {
        color: green;
      }
    }
  }
}

@media (min-width: 1000px) {
  .modal-xl {
    max-width: 1000px;
  }
}

@media (max-width: 1000px) {
  .modal-xl {
    min-width: 100%;
    margin: 0;

    .modal-content {
      border: 0;
    }
  }

  #modal-open-card {
    padding-left: 0 !important;
  }
}
</style>
