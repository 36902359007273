<template>
  <div class="payment_container p-3">
    <!-- <div class="d-flex justify-content-center">
      <b-button-group size="lg">
        <b-button variant="outline-secondary" :class="{'btn_active':activeBtnIndex == 0}" @click="onChain">On-Chain
        </b-button>
        <b-button variant="outline-secondary" :class="{'btn_active':activeBtnIndex == 1}" @click="onLightning">Lightning
        </b-button>
      </b-button-group>
    </div> -->
    <div class="text-right" v-if="showClose"><b-icon icon="x" scale="2" @click="closepayModal"></b-icon></div>
    <div class="rate_content ml-3 mr-3">
      <div class="d-flex justify-content-center  ">
        <div class="rate_recharge d-flex align-items-center">
          <div class="recharge_amount" v-if="payForm.payUsdtAmount ">
            {{ payForm.payUsdtAmount | payAmountFormatt(0) }}.<span class="text">{{ payForm.payUsdtAmount | payAmountFormatt(1) }}</span>
            <div class="invalid_line" v-if="expiresTime <= 0"></div>
          </div>
          <b-link v-if="!copySuccessPay" class="recharge_amount_copy pr-2" ref="copy" id="copypayamount" @click="copyPayAmount">copy</b-link>
          <div v-if="copySuccessPay"><b-icon icon="check" scale="1.5" style="color: #28A745;"></b-icon></div>
          <div class="d-flex align-items-center type"><img :src="require('@/assets/wallet/'+ codeInfo.payTypeName +'.png')" width="30" class="rounded ml-1" alt="USDT"></div>
        </div>
      </div>
      <div class="text-center mt-2 mb-2" v-if="!isRecharge"> Card Amount： $ {{ parseFloat(payForm.rechargeAmount).toFixed(2) }} </div>
      <div class="text-center mt-2 mb-2" v-if="isRecharge"> Recharge Amount： $ {{ parseFloat(payForm.rechargeAmount).toFixed(2) }} </div>
    </div>
    <div class="payAmount_tip text-center" v-if="expiresTime > 0">
      <div>必須轉入一模一樣的金額，否則無法入賬且平臺概不負責！</div>
      <div>transfer the exact amount!</div>
    </div>
    
    <div class="paycode_container ">
      <b-img fluid center  :src="codeInfo.payCodeImg" v-if="codeInfo.payCodeImg"/>
      <div class="text-center" v-else>
        <b-spinner></b-spinner>
      </div>
      <div class="code_expires d-flex justify-content-center align-items-center" v-if="expiresTime <= 0">
        <div class="code_logo  d-flex justify-content-center align-items-center">
          <h5 style="color: red;">Order expired !</h5>
        </div>
      </div>
    </div>
    <div class="text-center">Order expires in <span style="color: red; font-weight: bold;">{{ expiresTime | filterTime }}</span></div>
    <div class="text-center">Order No: {{ payForm.orderNo }}</div>
    <div class="text-center code_info mt-3" v-if="expiresTime > 0">
      <div >{{ codeInfo.chainName}}</div>
      <div class="d-flex align-items-center justify-content-center">
        <div class="code_text mr-3">{{codeInfo.codeText}}</div>
        <b-link v-if="!copySuccess"  id="copy" @click="copy" ref="copy" style="font-size: 0.8rem;">copy</b-link>
        <div v-if="copySuccess"><b-icon icon="check" scale="1.5" style="color: #28A745;"></b-icon></div>
      </div>
    </div>
    <div class="m-3 pr-3 pl-3">
      <b-button variant="primary" block @click="paidClick" :disabled="paidLoad>0">
        <div v-if="paidLoad"><b-spinner small variant="secondary" class="mr-3"></b-spinner>Querying...</div>
        <div v-else>I Have Paid</div>
      </b-button>
      <div class="pt-3 text-right">
        <b-link @click="cancelOrderModal">Cancel order</b-link>
      </div>
    </div>
    
    <b-modal id="cancel-order-modal" centered="" hide-header hide-footer size="sm">
      <div class="text-right"><b-icon icon="x" scale="1.8" @click="noCancelOrder"></b-icon></div>
      <div class="text-center pb-3 pt-2">Are you sure cancel the order?</div>
      <div><b-button block variant="primary" @click="cancelOrder">Yes, cancel the order</b-button></div>
    </b-modal>
  </div>
</template>

<script>
  import {
    getPayment,
    checkOpenCardPay,
    delCardOrder,
    delRechargeOrder,
    checkRechargePay,
  } from "@/api/card";
  import conf from "@/api/config";
  
  export default {
    props: {
      //payForm: paymethodId rechargeAmount orderNo expireDate payUsdtAmount
      payForm: {
        type: Object,
        default: null,
      },
      showClose: {
        type: Boolean,
        default: false,
      },
      //是否是充值窗口
      isRecharge: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        activeBtnIndex: 0,
        codeInfo: {
          payCodeImg: "",
          codeText: "",
          expiresTime: 3,//到期时间 3 分钟
          chainName: "",
          payTypeName: "USDT",
        },
        expiresTime: 0,
        timer: null,
        copySuccess: false,
        copySuccessPay: false,
        paidLoad: 0,
        paidTimer: null,
      }
    },
    filters: {
      currencyFilter(val) {
        if (val == "USD") {
          return "$";
        } else if (val == "CNY") {
          return "￥";
        } else {
          return val
        }
      },
      filterTime(val){
        if (val > 0){
          let m = parseInt(val/1000/60);
          let s = parseInt(val/1000 - m*60);
          m = m>=10? m : "0" + m;
          s = s>=10? s : "0" + s;
          return m + ":" + s ;
        } else {
          return "00:00"
        }
      },
      payAmountFormatt(val, index){
        if( val ){
          let strArr = val.toString().split(".");
          return strArr[index] ? strArr[index] : "00"
        } else {
          return "";
        }
      }
    },
    created(){
      this.getPaymentInfo()
    },
    methods: {
      copy(){
        let container = document.getElementById('copy')
        this.$copyText(this.codeInfo.codeText ,container).then( res => {
          this.copySuccess = true;
          setTimeout(()=> {
            this.copySuccess = false;
          },1000)
        })
      },
      copyPayAmount(){
        let container = document.getElementById('copypayamount')
        this.$copyText(this.payForm.payUsdtAmount.toString() ,container).then( res => {
          this.copySuccessPay = true;
          setTimeout(()=> {
            this.copySuccessPay = false;
          },1000)
        })
      },
      getPaymentInfo(){
        getPayment().then( res => {
          this.codeInfo.payTypeName = res.data.payTypeName;
          this.codeInfo.payCodeImg = conf.apiHost + res.data.payeeCodeImg
          this.codeInfo.codeText = res.data.payeeAccount
          this.codeInfo.chainName = res.data.chainName
          this.getExpiresT(res.timestamp);
        })
      },
      getExpiresT(timestamp){
        let expireDate = new Date(this.payForm.expireDate)
        let cha = expireDate - timestamp;
        if( cha > 0){
          this.expiresTime = cha ;
        } else {
          this.expiresTime = 0;
        }
        
        this.expiresTimeSet();
      },
      expiresTimeSet(){
        this.timer = setInterval(() => {
          this.expiresTime = this.expiresTime - 1000;
          if( this.expiresTime <= 0) {
            clearInterval(this.timer);
          }
        }, 1000);
      },
      paidClick(){
        let checkMethods = checkOpenCardPay
        if( this.isRecharge ) {
          checkMethods = checkRechargePay
        } else {
          checkMethods = checkOpenCardPay
        }
        // if( this.paidLoad > 0) return;
        this.paidLoad = 10;
        checkMethods(this.payForm.orderNo).then( res => {
          if( res.data ){
            clearInterval(this.paidTimer)
            this.$bvToast.toast('Payment succeeded',{
              title: "Operating tips",
              variant: "success",
              toaster: 'b-toaster-top-center',
              solid: true
            })
            this.$emit("hidePaid" , true);
          } else {
            this.setPaidTimer();
          }
        }).catch( err => {
          clearInterval(this.paidTimer)
          this.paidLoad = 0
        })
      },
      setPaidTimer(){
        this.paidTimer = setInterval(() => {
          this.paidLoad = this.paidLoad - 1;
          if( this.paidLoad <= 0){
            clearInterval(this.paidTimer)
            this.paidClick();
          }
        }, 1000)
      },
      closepayModal(){
        clearInterval(this.paidTimer)
        this.$emit("closepayModal" , true);
      },
      noCancelOrder(){
        this.$bvModal.hide('cancel-order-modal')
      },
      cancelOrderModal(){
        this.$bvModal.show('cancel-order-modal');
      },
      cancelOrder(){
        let delMethods = delCardOrder
        if( this.isRecharge ) {
          delMethods = delRechargeOrder
        } else {
          delMethods = delCardOrder
        }
        
        delMethods(this.payForm.orderNo).then( res => {
          this.$bvToast.toast('Cancellation succeeded',{
            title: "Operating tips",
            variant: "success",
            toaster: 'b-toaster-top-center',
            solid: true
          })
          setTimeout(() => {
            clearInterval(this.paidTimer)
            this.$bvModal.hide('cancel-order-modal');
            this.$emit("hidePaid" , true);
          } ,1000);
        }).catch( err => {
          this.$bvModal.hide('cancel-order-modal');
        })
      },
    },
  }
</script>

<style lang="scss">
  .payment_container {
    background-color: #fff;
    .btn-group {

      .btn-outline-secondary {
        border: 1px solid #ccc;
        font-weight: bold;
        padding: 0.5rem 1.5rem;
      }

      .btn-outline-secondary:hover {
        color: #6c757d;
        background-color: #fff;
      }

      .btn_active {
        color: #0667d0;
        background-color: #e3edf7;
        border: 2px solid #a2abce;

        &:hover {
          background-color: #e3edf7;
          color: #0667d0;
        }
      }
    }
    
    .payAmount_tip {
      color: red;
    }
    
    .rate_content {
      font-weight: bold;
      margin-top: 2rem;
    }

    .rate_recharge {
      border: 1px solid #ccc;
      border-radius: 0.3rem;

      >div {
        padding: 0.2rem 1rem;
      }
      
      .recharge_amount_copy {
        font-size: 0.9rem;
      }
      
      .type {
        border-left: 1px solid #ccc;
      }

      .recharge_amount {
        font-size: 1.5rem;
        position: relative;
        
        .invalid_line {
          position: absolute;
          width: 95%;
          left: 2%;
          top: 50%;
          height: 2px;
          background-color: red;
        }
        
        .text {
          color: red;
        }
      }
    }
    
    .paycode_container {
      position: relative;
      img {
        width: 60%;
      }
      .code_expires {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #e6e3e3db;
        
        .code_logo {
          width: 40%;
          height: 60%;
          background-image: url(../assets/index/code_ex.png);
          
          h5 {
            position: absolute;
          }
        }
      }
    }
    
    .code_info {
      color: #888;
      .code_text {
        color: #333;
        font-size: 0.8rem;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
    
  }
  
   @media (max-width: 1000px) {
     .payment_container .paycode_container {
       img {
         width: 30%;
       }
     }
   }
   @media (max-width: 750px) {
     .payment_container .paycode_container {
       img {
         width: 60%;
       }
     }
   }
</style>
