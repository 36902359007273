import request from './request'

// 获取卡片列表(可开卡BIN列表)
export function getCardList() {
  return request({
    url: '/api/card/bin/list',
    headers: {
      isToken: true
    },
    method: 'get',
  })
}

// 币种汇率
export function getExchange() {
  return request({
    url: '/api/pay/exchange',
    headers: {
      isToken: true
    },
    method: 'get',
  })
}

// 支付方式
export function getPayment() {
  return request({
    url: '/api/pay/config',
    headers: {
      isToken: true
    },
    method: 'get',
  })
}

// 创建卡片订单 amount cardBinId payConfigId payExchangeId
export function postCardOrder(data) {
  return request({
    url: '/api/card/order',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 我的开卡记录 pageNum pageSize status
export function getOpenCardRecords(data) {
  return request({
    url: '/api/card/order/list',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data,
  })
}

// 查询订单支付状态
export function checkOpenCardPay(id) {
  return request({
    url: `/api/card/order/check/pay/${id}`,
    headers: {
      isToken: true
    },
    method: 'get',
  })
}

// 开卡记录详情 id
export function getOrderDetail(id){
  return request({
    url: `/api/card/order/${id}`,
    headers: {
      isToken: true
    },
    method: 'get',
  })
}

// 首页 卡片列表 pageNum pageSize
export function getIndexCards(data) {
  return request({
    url: '/api/card/my/list',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data,
  })
}

// 首页 卡片交易记录 cardId pageNum pageSize
export function getIndexTransactions(data) {
  return request({
    url: '/api/card/my/bill/list',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data,
  })
}

// 首页 更新卡片账单
export function updateCardTransactions() {
  return request({
    url: '/api/card/my/bill',
    headers: {
      isToken: true
    },
    method: 'put',
  })
}

// 删除开卡订单
export function delCardOrder(id) {
  return request({
    url: `/api/card/order/${id}`,
    headers: {
      isToken: true
    },
    method: 'delete'
  })
}

// 申领卡片（订单失败时使用）
export function postApplicationCard(id) {
  return request({
    url: `/api/card/order/card/reissue`,
    headers: {
      isToken: true
    },
    method: 'post',
    data: {
      id: id
    }
  })
}

// 创建卡片充值订单 amount cardId payConfigId payExchangeId
export function postRecharge(data) {
  return request({
    url: `/api/card/topup`,
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 获取用户会员等级信息
export function getUserVip() {
  return request({
    url: `/api/user/vip`,
    headers: {
      isToken: true
    },
    method: 'get',
  })
}

// 删除充值订单
export function delRechargeOrder(id) {
  return request({
    url: `/api/card/topup/${id}`,
    headers: {
      isToken: true
    },
    method: 'delete'
  })
}

// 查询充值状态
export function checkRechargePay(id) {
  return request({
    url: `/api/card/topup/check/pay/${id}`,
    headers: {
      isToken: true
    },
    method: 'get',
  })
}

// 充值记录 pageNum pageSize status
export function getRechargeRecords(data) {
  return request({
    url: '/api/card/topup/list',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data,
  })
}

// 充值记录详情 id
export function getRechargeDetail(id){
  return request({
    url: `/api/card/topup/${id}`,
    headers: {
      isToken: true
    },
    method: 'get',
  })
}

