<template>
  <!-- 开卡模态框 -->
  <div class="create_new_card" :class="{'p-3': issmh5}">
    <div class="d-none d-lg-block d-xl-block text-right">
      <b-icon icon="x" class="h3" @click="closeModalCard" style="cursor: pointer;"></b-icon>
    </div>
    <div class="d-block d-lg-none d-xl-none" v-if="!isRecharge">
      <div class="row modal_title_h5">
        <div class="col-3">
          <h6 @click="closeModalCardH5" style="cursor: pointer;"><i class="icon icon-arrowleft"></i>Back</h6>
        </div>
        <div class="col-9">
          <h4>Create a New Card</h4>
        </div>
      </div>
    </div>
    <div class="d-block d-lg-none d-xl-none mb-2" v-if="isRecharge">
      <div class="row">
        <div class="col-9">
          <h4>Recharge</h4>
        </div>
        <div class="col-3 text-right">
          <b-icon icon="x" class="h3" @click="closeModalCard" style="cursor: pointer;"></b-icon>
        </div>
      </div>
    </div>
    <div class="row ocard_modal_content">
      <div class="col-md-6" v-if="!isRecharge">
        <h3 class=" d-none d-lg-block d-xl-block">Create a New Card</h3>
        <b-form>
          <b-form-group id="">
            <div class="cardduan">
              <label>Select Card BIN</label>
              <span class="grey card-title" v-if="currentCardSegment.description">(
                Card Introduction：{{currentCardSegment.description}} )</span>
            </div>
            <b-spinner v-if="cardLoading" label="Loading..." variant="secondary"></b-spinner>
            <div v-else>
              <b-row v-if="cardSegmentList.length > 0">
                <b-col :xs="3" :sm="3" :md="4" :cols="4" v-for="item in cardSegmentList" :key="item.id">
                  <div class="card-code-box" :class="{'isCurrentCard': item.id == currentCardSegment.id }"
                    @click="getCurrentCard(item)">
                    {{item.cardBin}}
                  </div>
                </b-col>
              </b-row>
              <div v-else class="m-3 " style="color: #888;">No card section</div>
            </div>
          </b-form-group>
        </b-form>
        <div class="left_card_surface text-right">
          <div class="right_text ">
            <h3>DaVinciCard</h3>
          </div>
          <h3 class="text-left" style="margin-top: -1rem;">{{ cardOpenForm.currency | currencyFilter }} {{ parseFloat(cardOpenForm.rechargeAmount || 0).toFixed(2) }}</h3>
          <div class="mini_text" style="margin-top: -1rem;">CARD NUMBER</div>
          <div class="row" style="margin: 0;">
            <div class="col-3" v-for="(arr , index) in binArr " :key="index">
              <div class="d-flex justify-content-end text">
                <div v-for="( item , i) in arr" style="width: 14px;">
                  <b-icon v-if="item == 'x'" icon="x"></b-icon>
                  <span v-else>{{ item }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end" style="margin: 0;">
            <div class="col-9">
              <div class="mini_text">Valid Thru</div>
              <div class="other_info"><b-icon icon="x"></b-icon><b-icon icon="x"></b-icon> / <b-icon icon="x"></b-icon><b-icon icon="x"></b-icon></div>
            </div>
            <div class="col-3">
              <div class="mini_text">CVV</div>
              <div class="other_info"><b-icon icon="x"></b-icon><b-icon icon="x"></b-icon><b-icon icon="x"></b-icon></div>
            </div>
          </div>
          <div class="h3 organization text-left">
            {{ currentCardSegment.type }}
          </div>
        </div>
        <div class="card_tips">
          <p>Note: this card will be valid for {{currentCardSegment.validDays}} days.</p>
          <p v-if="currentCardSegment.usablePlatform">Usable Platform: {{ currentCardSegment.usablePlatform }}</p>
          <p v-if="currentCardSegment.disablePlatform">Disable Platform: {{ currentCardSegment.disablePlatform }}</p>
        </div>
      </div>
      
      <div class="col-md-6" :class="isRecharge? 'col-md-12': 'pt-3'" v-if="!isReconfirmPayment">
        <b-form-group label="Card Amount" label-for="input-1" label-cols="5" class="right_input_form card_amount_form">
          <b-input-group>
            <b-form-input class="amount_number" v-model="cardOpenForm.rechargeAmount" placeholder="Enter Amount" @input="rechargeChange"></b-form-input>
            <b-input-group-append>
              <div>{{ cardOpenForm.currency }}</div>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        
        <div>
          <div class="vertical_line" style="height: 1em;"></div>
        </div>
        
        <b-form-group  label="Payment Method" label-for="input-1" label-cols="5" class="right_input_form">
          <div class="pay_method" @click="showConnectCoin">
            <h4 v-if="currentPayment">{{ currentPayment.chainName }}</h4> 
            <div v-else >No payment method</div>
            <b-icon icon="chevron-down" :rotate="showConnect? '180': '0'"></b-icon>
          </div>
        </b-form-group>
        <div v-if="showConnect">
          <div class="connect_coin d-flex align-items-center current_coin">
            <div class="icon_left">
              <i class="icon icon-c"></i>
            </div>
            <div class="connect_right">
              <div>{{currentPayment.chainName}}</div>
              <div><i>Payment limit {{ $NumFormat(currentPayment.payAmountMin) }} - {{  $NumFormat(currentPayment.payAmountMax)  }}</i></div>
            </div>
          </div>
        </div>
        
        <div class="d-flex justify-content-between">
          <div class="vertical_line">
            <div class="dot"></div>
          </div>
          <div class="connect_amount">
            <div>Conversion Rate <i>1 {{ currentRate.srcCurrency}} = ${{ $NumFormat(currentRate.rate) }}</i> </div>
            <div>Last updated 2 minutes ago</div>
            <div style="color: red;" class="ml-3" v-if="currentCardSegment.disablePlatform">
              Prohibited on these platforms: {{ currentCardSegment.disablePlatform }}
            </div>
          </div>
        </div>
        
        <b-form-group id="fieldset-1" class="right_input_form you_pay_form align-items-center" style="margin: 0 -5px;">
          <b-row style="padding: 0 5px;" class="align-items-center">
            <b-col :cols="7" :sm="9" :md="8">
              <div class="d-flex justify-content-between align-items-center">
                <div style="font-weight: 600;">You Pay</div>
                <div class="pl-3 text-right" v-if="cardOpenForm.rechargeAmount">{{ parseFloat(cardOpenForm.payAmount).toFixed(2) }}</div>
                <div class="pl-3 text-right" v-else>0.00</div>
              </div>
            </b-col>
            <b-col :cols="5" :sm="3" :md="4">
              <div class="d-flex align-items-center justify-content-end input-group-append" >
                <div>{{ currentRate.srcCurrency }}</div>
                <img src="@/assets/wallet/USDT.png"  v-if="currentRate.srcCurrency" width="30" height="30" class="rounded ml-1 mr-1">
              </div>
            </b-col>
          </b-row>
        </b-form-group>
        <div style="font-size: 0.8rem;color: #94959c;" class="d-flex pt-2">
          <div >Expense details：</div>
          <div class="pl-1" v-if="!isRecharge">Card Fee: ${{ parseFloat(currentCardSegment.cardFee).toFixed(2) }} + 
              Card Amount<span v-if="cardOpenForm.rechargeAmount">: ${{ cardOpenForm.rechargeAmount }}</span> + 
              Service Fee<span v-if="cardOpenForm.rechargeAmount">: ${{cardOpenForm.rechargeAmount}} * {{ parseFloat(currentCardSegment.serviceFee).toFixed(2) }}%</span>
              <span v-if="totalAmountUSD">= $ {{totalAmountUSD}} </span>
          </div>
          <div class="pl-1" v-else>
              Recharge Amount<span v-if="cardOpenForm.rechargeAmount">: ${{ cardOpenForm.rechargeAmount }}</span> + 
              Service Fee<span v-if="cardOpenForm.rechargeAmount">: ${{cardOpenForm.rechargeAmount}} * {{ parseFloat(userVip.serviceFee).toFixed(2) }}%</span>
              <span v-if="totalAmountUSD">= $ {{totalAmountUSD}} </span>
          </div>
        </div>
        
        <div>
          <b-button v-if="!isRecharge" block variant="primary" style="margin: 20px 0;" @click="continueClick">Continue</b-button>
          <b-button v-if="isRecharge" block variant="primary" style="margin: 20px 0;" @click="postRechargeCard">Recharge</b-button>
          <p class="text-center" style="color: red;" v-if="isError">Card must be greater than ${{payAmountMin}}</p>
        </div>
        
      </div>
      <div class="col-md-6 reconfirm_payment" style="padding-top: 20px;" v-if="isReconfirmPayment">
        <div class="d-flex">
          <h6 @click="paymentBack" style="cursor: pointer;"><i class="icon icon-arrowleft"></i>Back</h6>
        </div>
        <div>
          <h2>Is this the correct amount?</h2>
          <p>- This card will expire in {{currentCardSegment.validDays}} days.</p>
          <p>- Only USDT TCR20 payment is supported.</p>
          <div class="d-flex mb-2">
            <div class="mr-1">-</div>
            <div>This card is applicable to these platforms: {{ currentCardSegment.usablePlatform }}</div>
          </div>
          <div style="color: red;" class="d-flex mb-2" v-if="currentCardSegment.disablePlatform">
            <div class="mr-1">-</div>
            <div>Prohibit using this virtual credit card on these platforms: {{ currentCardSegment.disablePlatform }}</div>
          </div>
          <b-form-checkbox-group v-model="isRead" id="checkboxes-4">
            <b-form-checkbox value="true" style="color: #007BFF;">I understand these conditions</b-form-checkbox>
          </b-form-checkbox-group>
          <b-button block :variant="isRead[0]? 'primary': 'secondary'" @click="confirmPayment">Next Step</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getCardList,
    getExchange,
    getPayment,
    postCardOrder,
    postRecharge,
    getUserVip
  } from "@/api/card"; 
  
  export default {
    data(){
      return {
        isReconfirmPayment: false,
        isError: false,
        showConnect: false,
        currentRate: {},//汇率
        cardLoading: false,
        cardSegmentList: [], //卡段list
        //当前卡段
        currentCardSegment: {},
        binArr: [[],[],[],[]],
        cardOpenForm: {
          cardProductId: null, //卡段id
          rechargeAmount: 0,//充值金额
          paymentMethod: 0, //支付方式
          payAmount: 0, //支付金额
          currency: "USD",
        },
        totalAmountUSD: 0,
        rechargeAmount: 0,
        currentPayment: {},
        isRead: [],
        userVip: null,//用户Vip
        payAmountMin: 0,
      }
    },
    props: {
      issmh5: {
        type: Boolean,
        default: false,
      },
      //是否是充值窗口
      isRecharge: {
        type: Boolean,
        default: false,
      },
      currentRechargeCard: {
        type: Object,
        default: ()=> { return {}},
      },
    },
    created(){
      this.getRate();
      this.getPayment();
      if(!this.isRecharge){
        this.getCardList();
      } else {
        this.getUserVipInfo();
      }
    },
    filters: {
      currencyFilter(val) {
        if (val == "USD") {
          return "$";
        } else if (val == "CNY") {
          return "￥";
        } else {
          return val
        }
      },
    },
    methods: {
      showConnectCoin(){
        if( !this.currentPayment ) return;
        this.showConnect = !this.showConnect;
      },
      closeModalCard(){
        this.$emit('closeModalCard',false);
      },
      closeModalCardH5(){
        this.$emit('closeModalCardH5', false);
      },
      // 获取支付方式
      getPayment(){
        getPayment().then( res => {
          this.currentPayment = res.data;
          if( this.isRecharge ){
            this.payAmountMin = this.currentPayment.payAmountMin
          }
        })
      },
      // 币种汇率
      getRate(){
        getExchange().then( res => {
          this.currentRate = res.data;
        })
      },
      // 获取卡段列表
      getCardList(){
        this.cardLoading = true;
        getCardList().then( res => {
          this.cardSegmentList = res.data;
          this.cardLoading = false;
          this.currentCardSegment = this.cardSegmentList[0];
          this.payAmountMin = this.currentCardSegment.payAmountMin;//卡段最低限额
          this.cardOpenForm.cardProductId = this.currentCardSegment.id;
          let arr = this.currentCardSegment.cardBin.split("");
          for( let i = 0 , j = 0 ; i < 16 ; i++){
            if( arr[i] ){
              this.binArr[j].push(arr[i])
            } else {
              this.binArr[j].push('x')
            }
            if( i%4 == 3){ 
              j++;
            }
          }
        }).catch( err => {
          this.cardLoading = false;
        })
      },
      //选择当前卡
      getCurrentCard(item) {
        this.currentCardSegment = item;
        let arr = this.currentCardSegment.cardBin.split("");
        this.binArr = [[],[],[],[]];
        for( let i = 0 , j = 0 ; i < 16 ; i++){
          if( arr[i] ){
            this.binArr[j].push(arr[i])
          } else {
            this.binArr[j].push('x')
          }
          if( i%4 == 3){ 
            j++;
          }
        }
        this.cardOpenForm.cardProductId = this.currentCardSegment.id;
        this.payAmountMin = this.currentCardSegment.payAmountMin;
      },
      getUserVipInfo(){
        getUserVip().then(res => {
          this.userVip = res.data;
        })
      },
      //计算支付金额
      rechargeChange(){
        if( this.cardOpenForm.rechargeAmount <= 0) this.cardOpenForm.rechargeAmount = 0;
        if( isNaN(this.cardOpenForm.rechargeAmount)) {
          this.cardOpenForm.payAmount = 0
        } else {
          if( this.isRecharge ){
            //要加vip级别的服务费
            let serFee = (this.userVip.serviceFee / 100) * parseFloat(this.cardOpenForm.rechargeAmount)
            let allRecharge = parseFloat(this.cardOpenForm.rechargeAmount) + serFee;
            this.totalAmountUSD = allRecharge;
            this.cardOpenForm.payAmount = allRecharge / this.currentRate.rate
          } else {
            let serFee = (this.currentCardSegment.serviceFee / 100) * parseFloat(this.cardOpenForm.rechargeAmount)
            let allRecharge = parseFloat(this.cardOpenForm.rechargeAmount) + this.currentCardSegment.cardFee + serFee
            this.totalAmountUSD = allRecharge;
            let payAmount = allRecharge / this.currentRate.rate;
            this.cardOpenForm.payAmount = payAmount
          }
        }
        
        if(this.cardOpenForm.rechargeAmount < this.payAmountMin ){
          this.isError = true;
        } else {
          this.isError = false;
        }
      },
      continueClick(){
        if(this.cardOpenForm.rechargeAmount < this.payAmountMin ){
          this.isError = true;
          return;
        } else {
          this.isError = false;
        }
        this.isReconfirmPayment = true;
      },
      paymentBack(){
        this.isReconfirmPayment = false;
      },
      //再次确认支付
      confirmPayment(){
        if( !this.isRead[0] ) return;
        let payForm = {
          amount: parseFloat(this.cardOpenForm.rechargeAmount) ,//充值金额 美元
          cardBinId: this.currentCardSegment.id, //卡Id
          payConfigId: this.currentPayment.id, //支付方式Id
          payExchangeId: this.currentRate.id,//币种汇率id
        }
        postCardOrder(payForm).then( res => {
          if( res.data ){
            let order = res.data;
            this.$set(order, 'paymethodId',this.currentPayment.id)
            this.$set(order, 'rechargeAmount',this.cardOpenForm.rechargeAmount)
            this.$emit("confirmPayment" , order);
          }
        }).catch( err => {
          console.log(err)
        })
        
      },
      postRechargeCard(){
        postRecharge({
          amount: parseFloat(this.cardOpenForm.rechargeAmount),
          cardId: this.currentRechargeCard.id,
          payConfigId: this.currentPayment.id,
          payExchangeId: this.currentRate.id
        }).then( res => {
          let order = res.data;
          this.$set(order, 'paymethodId',this.currentPayment.id)
          this.$set(order, 'rechargeAmount',this.cardOpenForm.rechargeAmount)
          this.$emit("confirmPayment" , order);
        }).catch( err => {
        })
      },
    }
  }
</script>

<style lang="scss">
  
  .create_new_card {
    background-color: #fff;
    
    .card_tips {
      p{
        margin-bottom: 0;
        color: #888;
      }
    }
    .modal_title_h5 {
      border-bottom: 1px solid #ccc;
      padding: 10px 0;
      margin-bottom: 10px;
      .b-icon {
        font-weight: bolder;
      }
      .col-3 {
        display: flex;
        align-items: flex-end;
      }
      .col-9 {
        text-align: right;
      }
    }
    
    .ocard_modal_content {
      padding: 30px;
  
      .cardduan {
        margin-bottom: 10px;
        
        .card-title {
          font-size: 0.8em;
          color: #888;
          line-height: 0.8em;
          font-weight: normal;
          margin-left: 5px;
        }
  
        label {
          margin-bottom: 0;
        }
      }
      
      .card-code-box {
        border: 1px solid #dce3eb;
        border-radius: 0.2em;
        text-align: center;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 0.1rem 0.3rem;
        
        &.isCurrentCard {
          color: #fff;
          background-color: $theme;
        }
      }
      
      .left_card_surface {
        background-color: #000000;
        box-shadow: 0 4px 5px 3px #a9a9a9;
        color: #fff;
        border-radius: 20px;
        padding: 20px 20px;
        margin: 0 auto 1rem;
        background-image: url(../assets/index/card_bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        max-width: 390px;

        
        .h5 {
          font-weight: normal;
          margin-bottom: 0;
        }
        .text {
          font-size: 1.5rem;
        }
        
        .organization {
          text-transform: uppercase;
          margin-bottom: 0;
        }
        
        .col-3 , .col-4{
          padding-left: 0;
          padding-right: 0;
        }
      }
      
      .vertical_line {
        width: 2px;
        background-color: #5f6066;
        margin-left: 25px;
        align-self: stretch;
        
        .dot {
          width: 0.6em;
          height: 0.6em;
          margin-top: 0.8em;
          transform: translateX(-40%);
          background-color: #5f6066;
          border-radius: 50%;
        }
      }
     
      .right_input_form {
        border: 1.2px solid #e2e2e2;
        border-radius: 5px;
        padding: 0 0 0 10px;
        margin-bottom: 0;
        
        .amount_number {
        }
        
        .col-form-label {
          font-weight: 600;
          display: flex;
          align-items: center;
        }
        
        .form-control {
          text-align: right;
          border: 0;
          border-radius: 0;
        }
        
        .input-group-append {
          display: flex;
          align-items: center;
        }
      }
      
      .card_amount_form {
        .form-control {
          border-bottom: 1px solid #000;
          margin-top: 10px;
          margin-right: 6px;
        }
        .input-group-append {
          padding: 16px 10px;
          border-left: 2.6px solid #e2e2e2;
        }
      }
      
      .pay_method {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        align-self: stretch;
        padding: 30px 0;
        
        h4 {
          margin-bottom: 0;
          font-weight: normal;
          cursor: pointer;
        }
        
        .b-icon {
          color: $theme;
          font-weight: bolder;
          margin-left: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
      
      .connect_amount {
        color: #94959c;
        text-align: right;
        font-size: 0.9em;
        padding: 10px 0;
        i {
          color: #333;
          font-size: 1em;
          font-weight: bold;
        }
      }
      
      .connect_coin {
        border: 1px solid #888;
        margin: 0 -5px;
        cursor: pointer;
        .icon_left{
          width: 2.5em;
          background-color: #888;
          color: #fff;
          align-self: stretch;
          display: flex;
          align-items: center;
          justify-content: center;
          
          .icon {
            font-size: 2em;
          }
        }
        
        .connect_right {
          padding-left: 10px;
          line-height: 1em;
          i {
            font-size: 0.7em;
          }
        }
        
        &.current_coin {
          border: 1px solid $theme;
          .icon_left {
            background-color: $theme;
          }
        }
      }
      
      .you_pay_form {
        .form-control {
          margin-top: 5px;
        }
        .input-group-append {
          padding: 10px 10px 10px 0; 
          border-left: 1.2px solid #e2e2e2;
        }
      }
      
      .rate_list {
        .rate_item {
          border: 1px solid #e2e2e2;
          margin: 0 -5px;
          cursor: pointer;
          
          .left {
            border-right: 1px solid #e2e2e2;
            padding: 0.4rem 0.6rem;
            background-color: #ccc;
          }
          
          .right {
            font-size: 0.9rem;
          }
          
          &.current_rate {
            border: 1px solid $theme;
            .left {
              color: #fff;
              background-color: $theme;
            }
            
          }
        }
      }
      
    }
  
  
    .right_text {
      display: flex;
      justify-content: flex-end;
    }
  
    .mini_text {
      font-size: 0.8em;
    }
    
    .reconfirm_payment {
      h6 {
        cursor: pointer;
      }
      p {
        margin-bottom: 5px;
      }
      
      .btn {
        margin-top: 30px;
      }
    }
  }
  
  @media (max-width: 1000px) {
    .create_new_card .ocard_modal_content {
      padding: 0!important;
      // height: 100vh;
    }
  }
  
  
</style>
